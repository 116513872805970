<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/menuCategories" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span v-if="$route.name === 'AddMenuCategory'">
          Дотоод ангилал нэмэх
          <div class="editField">
            <el-row :gutter="16">
              <el-col :span="16" :offset="4">
                <div class="panel">
                  <el-form
                    :model="menuCategory"
                    :rules="rules"
                    ref="menuCategory"
                    label-width="220px"
                    class="demo-ruleForm"
                  >
                    <el-form-item
                      label="Дотоод ангилал монгол нэр"
                      prop="name_mon"
                    >
                      <el-input
                        style="width:250px"
                        size="mini"
                        v-model="menuCategory.name_mon"
                        placeholder="Дотоод ангилал монгол нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Дотоод ангилал англи нэр"
                      prop="name_eng"
                    >
                      <el-input
                        style="width:250px"
                        size="mini"
                        v-model="menuCategory.name_eng"
                        placeholder="Категори англи нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Төрөл /сервес/" prop="service">
                      <el-select
                        placeholder="Үйлчилгээ"
                        v-model="menuCategory.service"
                      >
                        <el-option
                          v-for="service in servicesList"
                          :label="service.name_mon"
                          :value="service.name_eng"
                          :key="service.name_eng"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-button
                      type="primary"
                      style="margin-right: 20;"
                      @click="save"
                      >Нэмэх</el-button
                    >
                    <router-link to="/menuCategories" class="back-button">
                      <el-button>
                        Буцах
                      </el-button>
                    </router-link>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  name: "AddMenuCategory",
  created() {
    this.getServices();
    if (this.$route.name === "createCategory") {
      this.$notify({
        title: "Категори мэдээлэл нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
  },

  data() {
    return {
      servicesList: [],
      loadUpload: false,
      imageNull: "none",
      bannerDetail: {},
      menuCategory: {
        name_mon: "",
        name_eng: "",
        service: ""
      },
      rules: {
        name_mon: [
          {
            required: true,
            message: "Та категори нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та категори англи нэр уу",
            trigger: "blur"
          }
        ],
        service: [
          {
            required: true,
            message: "Та сервис сонгоно уу",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    save() {
      this.$refs.menuCategory.validate(valid => {
        if (valid) {
          service.createMenuCategory(this.menuCategory).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Дотоод ангилал нэмэгдлээ",
                type: "success"
              });
              this.$router.go(-1);
            } else {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Дотоод ангилал нэмэхэд алдаа гарлаа" +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    getServices() {
      service.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
